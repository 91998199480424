<template>
  <div id="mine">
    <BottomNav />
    <van-nav-bar title="我的" right-text="签到" :border="false" @click-right="goSubPage('set')">
      <template #right>
        <van-icon name="setting-o" size="2.1rem" />
      </template>
    </van-nav-bar>
    <div class="topBac">
    </div>
    <div class="user-info-wrap">
      <img :src="`${cdnHttp}${worker_profile.avatar}`" alt="" class="avatar" v-if="worker_profile.avatar">
      <img :src="`${MEDIA_URL.avatar}`" alt="" class="avatar" v-else>
      <div class="user-info-item ">
        <p>{{worker_profile.name?worker_profile.name:"请设置姓名"}} </p>
        <p> {{worker_profile.mobile}}</p>
      </div>
      <div class="data-screening">
        <div>
          <p>{{worker_profile.balance?worker_profile.balance:"0.00"}}元</p>
          <p>余额</p>
        </div>
        <div>
          <p>{{worker_profile.security_diposit?worker_profile.security_diposit:"0.00"}}元</p>
          <p>保证金</p>
        </div>
      </div>
    </div>
    <div class="user-operation-block">
      <van-cell is-link v-for="(item,index) in menu_list" :key="index" @click="goSubPage(item.route_name)">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <svg class="icon icon-reset-user-operation" aria-hidden="true">
            <use :xlink:href="`#${item.icon}`"></use>
          </svg>
          <span class="custom-title">{{item.name}}</span>
        </template>
      </van-cell>
    </div>
    <div class="user-operation-block">
      <van-cell is-link @click="goSubPage('set')">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <svg class="icon icon-reset-user-operation" aria-hidden="true">
            <use xlink:href="#icon-shezhi1"></use>
          </svg>
          <span class="custom-title">设置</span>
        </template>
      </van-cell>
    </div>
  </div>
</template>
<script>
import BottomNav from "@/components/NavBar/BottomNav.vue";
export default {
  data() {
    return {
      worker_profile: {},
      menu_list: [
        {
          name: "服务质量分",
          icon: "icon-dongtai",
          route_name: "quality_score",
          value: "",
        },
        {
          name: "钱包",
          icon: "icon-qianbao1",
          route_name: "worker_wallet",
          value: "",
        },
        {
          name: "个人资料",
          icon: "icon-jizan-",
          route_name: "personal-info",
        },
        {
          name: "服务承诺",
          icon: "icon-jizan-",
          route_name: "promise_data",
        },
        // {
        //   name: "退款记录",
        //   icon: "icon-youji",
        //   route_name: "",
        //   value: "i",
        // },
        // {
        //   name: "退款记录",
        //   icon: "icon-youji",
        //   route_name: "",
        //   value: "i",
        // },
      ],
    };
  },
  created() {
    this.getWorkerProfile();
  },
  methods: {
    /**
     *  获取师傅我的页面信息
     */
    getWorkerProfile() {
      this.$http.get("api/v2/worker/profile/center").then((res) => {
        console.log(res.data);
        this.worker_profile = res.data.profile;
      });
    },
  },
  components: {
    BottomNav,
  },
};
</script>
<style lang="less">
#mine {
  .topBac {
    width: 100%;
    height: 20rem;
    // background: @themeColor;
    width: 100%;
    // height: 20rem;
    background: linear-gradient(to bottom, #ff7963, #ff816c00);
    box-sizing: border-box;
  }
  .icon-reset-user-wallet {
    width: 3rem;
    height: 3rem;
  }
  .icon-reset-user-operation {
    width: 3rem;
    height: 3rem;
  }
  .user-info-wrap {
    margin: 0 1rem;
    background: #fff;
    padding-bottom: 1rem;
    border-radius: 10px;
    transform: translateY(-13rem);
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 10px 0px;
    .avatar {
      position: absolute;
      width: 7rem;
      height: 7rem;
      border-radius: 100rem;
      left: calc(50% - 3.5rem);
      top: -3rem;
    }
    .user-info-item {
      padding: 5rem 0 1rem 0;
      text-align: center;
      height: 4rem;
      p {
        padding: 0.2rem 0;
      }
      p:first-child {
        font-size: @theme-font-size-l;
        font-weight: 600;
      }
      p:last-child {
        font-size: @theme-font-size-m;
      }
    }
    .data-screening {
      display: flex;
      align-items: center;
      div {
        flex: 1;
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          padding: 0.2rem 0;
          font-size: @theme-font-size-m;
        }
        p:first-child {
          font-size: @theme-font-size-l;
          font-weight: 600;
          color: @themeColor;
        }
      }
    }
  }
  .user-operation-block {
    transform: translateY(-12rem);
    margin: 1rem 1rem 2rem 1rem;
    overflow: hidden;
    border-radius: 10px;
    .van-cell {
      align-items: center;
      // padding: 0.6rem 1.1rem;
    }
    .van-cell__title {
      display: flex;
      align-items: center;
      .custom-title {
        margin-left: 1.1rem;
      }
    }
  }
}
</style>
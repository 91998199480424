<template>
  <div id="bottomNav">
    <div class="NavInfo" v-for="(item,index) in navInfoList" :key="index" @click="goSubPage(item.route_name)">
      <img :src="item.route_name == $route.name ? item.actSrc : item.src" class="publicImg" alt />
      <span :class="item.route_name == $route.name ? 'NavTitleAct': 'NavTitle'">{{item.title}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navInfoList: [
        {
          title: "首页",
          src: require("@/assets/images/bottomNav/home.png"),
          actSrc: require("@/assets/images/bottomNav/homeact.png"),
          route_name: "home",
        },
        {
          title: "订单",
          src: require("@/assets/images/bottomNav/order.png"),
          actSrc: require("@/assets/images/bottomNav/orderact.png"),
          route_name: "order",
        },
        {
          title: "钱包",
          src: require("@/assets/images/bottomNav/learn.png"),
          actSrc: require("@/assets/images/bottomNav/learnact.png"),
          route_name: "wallet",
        },
        {
          title: "我的",
          src: require("@/assets/images/bottomNav/mine.png"),
          actSrc: require("@/assets/images/bottomNav/mineact.png"),
          route_name: "mine",
        },
      ],
      tempRoute: "",
    };
  },
  methods: {
    jumpPage(item) {
      this.$router.push(item.path);
    },
  },
  created() {
    this.tempRoute = this.$route.path;
  },
};
</script>
<style lang="less">
#bottomNav {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 46px;
  width: 100%;
  z-index: 888;
  // background: rgb(248, 246, 246);
  border-top: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: -1px -1px 10px 0 #f0f0f0;
  display: flex;
  justify-content: space-around;
  .NavInfo {
    height: 100%;
    // background: gold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 2.8rem;
      height: 2.8rem;
    }
    .NavTitleAct {
      color: #ff7963;
    }
    .NavTitle {
      color: #b1b0b0;
    }
  }
}
</style>